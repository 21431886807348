import styles from './ChooseUs.module.css'

import expertise from '../assets/icons/expertise.png'
import client from '../assets/icons/client.png'
import innovation from '../assets/icons/innovation.png'
import results from '../assets/icons/results.png'

function ChooseUs() {

    return (
        <div className={styles.mainContainer}>
            <h2>Why Choose Us</h2>
            <div className={styles.contentContainer}>
                <div className={styles.contentCard}>
                    <div className={styles.titleContainer}>
                        <img src={expertise} alt='Expertise' />
                        <p>Expertise</p>
                    </div>
                    <div className={styles.textContainer}>
                        <p>
                            With years of experience in the industry, our team possesses the
                            skills andknowledge needed to deliver high-quality web solutions that exceed expectations.
                        </p>
                    </div>
                </div>
                <div className={styles.contentCard}>
                    <div className={styles.titleContainer}>
                        <img src={client} alt='Client Centric Approach' />
                        <p>Client Centric Approach</p>
                    </div>
                    <div className={styles.textContainer}>
                        <p>
                            We believe in building long-lasting relationships
                            with our clients based on trust, transparency, and collaboration. Our team is committed to understanding our clients' goals and delivering solutions that align with their vision and objectives.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentCard}>
                    <div className={styles.titleContainer}>
                        <img src={innovation} alt='innovation' />
                        <p>Innovation</p>
                    </div>
                    <div className={styles.textContainer}>
                        <p>
                            We thrive on innovation and continuously strive to stay ahead of the curve by adopting the latest technologies and trends in web development and design.
                        </p>
                    </div>
                </div>
                <div className={styles.contentCard}>
                    <div className={styles.titleContainer}>
                        <img src={results} alt='results' />
                        <p>Results Driven</p>
                    </div>
                    <div className={styles.textContainer}>
                        <p>
                            Ultimately, our success is measured by the success of our clients. We are dedicated to delivering tangible results and helping our clients achieve their business objectives through our web solutions.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ChooseUs