import styles from './Footer.module.css'

function Footer() {

    const date = new Date()

    return (
        <div className={styles.footerContainer}>
            <p>
                &copy; <span>Copyright {String(date).slice(11, 15)} - Sigmatech Web Solutions LLC - All rights reserved.</span>
            </p>
        </div>
    )
}

export default Footer