import styles from './OurServices.module.css'

function OurServices() {

    return (
        <div className={styles.mainContainer}>
            <h2>Our Services</h2>
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <h3>Web Development</h3>
                    <div className={styles.servicesCard}>
                        <p>
                            Our proficient developers are adept at building custom websites from scratch, ensuring that each site is fully optimized for performance, responsiveness, and user experience. Whether it's a corporate website, e-commerce platform, or web application, we have the expertise to bring your vision to life.
                        </p>
                    </div>
                </div>
                <div className={styles.content}>
                    <h3>UI/UX Design</h3>
                    <div className={styles.servicesCard}>
                        <p>
                            We understand the importance of intuitive and visually appealing design in captivating and engaging users. Our creative designers work closely with clients to design interfaces that not only look stunning but also provide seamless navigation and optimal user experience across all devices.
                        </p>
                    </div>
                </div>
                <div className={styles.content}>
                    <h3>Digital Strategy</h3>
                    <div className={styles.servicesCard}>
                        <p>
                            Beyond just development and design, we offer comprehensive digital strategy services aimed at maximizing the online presence and impact of our clients' businesses. From SEO and content strategy to social media marketing and conversion optimization, we help businesses achieve their goals and stay ahead in the digital landscape.
                        </p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default OurServices