import styles from './Mern.module.css'
import mern from '../assets/img/Background_3.png'

function Mern() {

    return (
        <div className={styles.mainContainer}>
            <h2>Build Robust and Scalable Web Applications</h2>
            <div className={styles.contentContainer}>
                <img src={mern} alt='MERN' />
                <div className={styles.textContainer}>
                    <h3>
                        MERN stack is a collection of technologies that enables faster application development.
                    </h3>
                </div>
            </div>

        </div>
    )
}

export default Mern