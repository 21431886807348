import styles from './HeroBanner.module.css'

function HeroBanner({ scrollTouch }) {

    return (
        <div className={styles.mainContainer}>
            <div className={styles.contentContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.mainText}>
                        <h2>
                            Increase your Business <br></br>with us!
                        </h2>
                    </div>
                    <div className={styles.subText}>
                        <p>
                            We are dedicated to crafting exceptional web solutions tailored to meet the unique needs of our clients. With a team of experienced
                            developers, designers, and strategists, we specialize in creating dynamic and
                            innovative digital experiences that drive results.
                        </p>
                    </div>
                </div>
                <div className={styles.callContainer}>
                    <button onClick={scrollTouch}>Contact us</button>
                </div>
            </div>
        </div>
    )
}

export default HeroBanner