import { useState } from 'react'
import styles from './BurgerMenu.module.css'

function BurgerMenu({ scrollAbout, scrollServices, scrollChoose, scrollTouch }) {

    const [menuOpen, setMenuOpen] = useState(false)

    function handleMenu() {
        setMenuOpen(!menuOpen)
    }

    return (
        <div className={styles.container} onClick={handleMenu}>
            <div className={styles.bar}></div>
            <div className={styles.bar}></div>
            <div className={styles.bar}></div>

            {menuOpen &&
                <div className={styles.background} onClick={handleMenu}>
                    <div className={styles.menu}>
                        <h2>Home</h2>
                        <h2 onClick={scrollAbout}>About us</h2>
                        <h2 onClick={scrollServices}>Services</h2>
                        <h2 onClick={scrollChoose}>Why choose us</h2>
                        <h2 onClick={scrollTouch}>Get in touch</h2>
                    </div>
                </div>

            }

        </div>
    )
}

export default BurgerMenu