import styles from './AboutUs.module.css'

function AboutUs() {

    return (
        <div className={styles.mainContainer}>
            <h2>About us</h2>
            <h3>
                Welcome to Sigmatech Web Solutions LLC, where we are dedicated to crafting exceptional web solutions tailored to meet the unique needs of our clients.
            </h3>
            <div className={styles.contentContainer}>
                <div className={styles.textContainer}>
                    <p>
                        At Sigmatech, we understand that every business is distinct, with its own set of challenges and goals. That's why we pride ourselves on our ability to listen closely to our clients, understand their vision, and then bring it to life through innovative digital solutions.
                    </p>
                    <p>
                        Our team consists of experienced developers, designers, and strategists who are passionate about what they do. With their expertise and creativity, we specialize in creating dynamic and innovative digital experiences that drive results for our clients.
                    </p>
                    <p>
                        Whether you're looking to establish a strong online presence, enhance user engagement, or streamline your business processes, Sigmatech is here to help. We believe in building long-lasting relationships with our clients, based on trust, transparency, and exceptional service.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default AboutUs