import styles from './App.module.css'
import Navbar from './components/Navbar';
import HeroBanner from './components/HeroBanner';
import AboutUs from './components/AboutUs';
import OurServices from './components/OurServices';
import ChooseUs from './components/ChooseUs';
import Mern from './components/Mern';
import GetInTouch from './components/GetInTouch';
import Footer from './components/Footer';

import { useRef } from 'react';

function App() {

  const About = useRef(null)
  const Services = useRef(null)
  const Choose = useRef(null)
  const Touch = useRef(null)

  const scrollAtAbout = () => About.current.scrollIntoView()
  const scrollAtServices = () => Services.current.scrollIntoView()
  const scrollAtChoose = () => Choose.current.scrollIntoView()
  const scrollAtTouch = () => Touch.current.scrollIntoView()

  return (
    <section>
      <div className={styles.homeFisrtSection}>
        <Navbar
          scrollAbout={scrollAtAbout}
          scrollServices={scrollAtServices}
          scrollChoose={scrollAtChoose}
          scrollTouch={scrollAtTouch}
        />
        <HeroBanner
          scrollTouch={scrollAtTouch}
        />
      </div>
      <div className={styles.homeSecondSection} ref={About}>
        <AboutUs />
      </div>
      <div className={styles.homeSecondSection} ref={Services}>
        <OurServices />
      </div>
      <div className={styles.homeSecondSection} ref={Choose}>
        <ChooseUs />
      </div>
      <div className={styles.homeSecondSection}>
        <Mern />
      </div>
      <div className={styles.homeSecondSection} ref={Touch}>
        <GetInTouch />
      </div>
      <Footer />
    </section>
  );
}

export default App;
