import styles from './Navbar.module.css'
import BurgerMenu from './BurgerMenu'

import logo from '../assets/img/logo.png'

function Navbar({ scrollAbout, scrollServices, scrollChoose, scrollTouch }) {

    return (
        <section>
            <div className={styles.homeNavibar}>
                <div className={styles.navibarLogo}>
                    <img src={logo} alt='Sigmatech Web Solutions' />
                </div>
                <div className={styles.navibarContentMobile}>
                    <BurgerMenu
                        scrollAbout={scrollAbout}
                        scrollServices={scrollServices}
                        scrollChoose={scrollChoose}
                        scrollTouch={scrollTouch} />
                </div>
                <div className={styles.navibarContentDesk}>
                    <h2>Home</h2>
                    <h2 onClick={scrollAbout}>About us</h2>
                    <h2 onClick={scrollServices}>Services</h2>
                    <h2 onClick={scrollChoose}>Why choose us</h2>
                    <h2 onClick={scrollTouch}>Get in touch</h2>
                </div>
            </div>
            <div className={styles.navibarBorder}>

            </div>
        </section>

    )
}

export default Navbar