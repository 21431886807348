import styles from './GetInTouch.module.css'

import { useState } from 'react'
import emailjs from '@emailjs/browser'

function GetInTouch() {

    const [message, setMessage] = useState({})
    const [sent, setSent] = useState(false)

    function handleOnChange(e) {
        setMessage({ ...message, [e.target.name]: e.target.value })
        setSent(false)
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (message.name && message.email && message.phone && message.reason && message.know && message.message) {

            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_PUBLIC_KEY)
            setSent(true)
        }

        // Clean the form after sent
        setMessage({})
    }

    return (
        <div className={styles.mainContainer}>
            <h2>Get in Touch</h2>
            <p>
                Ready to take your online presence to the next level? Contact us today to discuss your project and discover how we can help you succeed in the digital world.
            </p>
            <div className={styles.secondContainer}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.formContainer}>
                        <input
                            className={styles.nameInput}
                            type='text'
                            id='name'
                            name='name'
                            placeholder='Name:'
                            value={message.name || ""}
                            onChange={handleOnChange}
                        />
                        <div className={styles.inputContainer}>
                            <input
                                type='text'
                                id='email'
                                name='email'
                                placeholder='E-mail:'
                                value={message.email || ""}
                                onChange={handleOnChange}
                            />
                            <input
                                type='tel'
                                id='phone'
                                name='phone'
                                placeholder='Phone:'
                                value={message.phone || ""}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <input
                                type='text'
                                id='reason'
                                name='reason'
                                placeholder='Reason for contact'
                                value={message.reason || ""}
                                onChange={handleOnChange}
                            />
                            <input
                                type='text'
                                id='know'
                                name='know'
                                placeholder='How did you know about us'
                                value={message.know || ""}
                                onChange={handleOnChange}
                            />
                        </div>
                        <textarea
                            className={styles.textContainer}
                            id='message'
                            name='message'
                            placeholder='Describe how we can help'
                            value={message.message || ""}
                            onChange={handleOnChange}
                        />
                        <div className={styles.callContainer}>
                            <button type='submit'>send</button>
                            {sent &&
                                <b>Success! Thank you for getting in contact with us.</b>
                            }
                        </div>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default GetInTouch